import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="faq"
export default class extends Controller {
  static targets = [ "answer"]
  initialize() {
    this.hide_all_answer
  }

  show_answer(event) {
    const answer = event.currentTarget.nextSibling.nextSibling;
    answer.classList.toggle("hidden")
  }

  hide_all_answer() {
    this.answerTargets.forEach(answer => {
      answer.classList.add("hidden")
    })
  }
}
