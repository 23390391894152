import { Controller } from '@hotwired/stimulus'

// Import TinyMCE
import tinymce from 'tinymce/tinymce'
import 'tinymce/models/dom/model'
// Import icons
import 'tinymce/icons/default/icons'

// Import theme
import 'tinymce/themes/silver/theme';

// Import skin
//import 'tinymce/skins/ui/oxide/skin.min.css';

// Import plugins

// import 'tinymce/plugins/advlist';
// import 'tinymce/plugins/anchor';
// import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
// import 'tinymce/plugins/autosave';
// import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
// import 'tinymce/plugins/directionality';
// import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
// import 'tinymce/plugins/nonbreaking';
// import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
// import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
// import 'tinymce/plugins/template';
// import 'tinymce/plugins/visualblocks';
// import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';


export default class extends Controller {
  static targets = ['input']

  initialize () {
    this.defaults = {
      target: this.inputTarget,
      base_url: '/assets/tinymce/',
      content_css: false,
      file_picker_types: 'file image media',
      image_caption: true,
      toolbar: ['blocks | bold italic underline | blockquote link | alignleft aligncenter alignright | bullist numlist outdent indent | image media',
                'undo redo | table codesample insertdatetime | fullscreen code | help'
              ],
      mobile: {
        toolbar: [
          'blocks | bold italic underline | blockquote link',
          'bullist numlist outdent indent | image media',
          'alignleft aligncenter alignright',
          'undo redo | table codesample insertdatetime | fullscreen code | help'
        ]
      },
      plugins: 'link lists fullscreen help preview table code autoresize wordcount image media insertdatetime codesample',
      menubar: false,
      style_formats: [
        { title: 'Heading 2', format: 'h2' },
        { title: 'Heading 3', format: 'h3' },
        { title: 'Heading 4', format: 'h4' },
        { title: 'Heading 5', format: 'h5' },
        { title: 'Heading 6', format: 'h6' },
        { title: 'Paragraph', format: 'p'}
      ],
      height: 500,
      max_height: 700,
      default_link_target: '_blank',
      link_title: false,
      //autoresize_bottom_margin: 10,
      link_context_toolbar: true,
      relative_urls: false,

      //element_format: 'html',
      //invalid_elements: ['span'],
      //content_style: 'html { font-family: Roboto, sans-serif; line-height: 1.5; }'
    }
  }

  connect () {
    //let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(this.defaults)
  }

  get preview () {
    return (
      document.documentElement.hasAttribute('data-turbolinks-preview') ||
      document.documentElement.hasAttribute('data-turbo-preview')
    )
  }

  disconnect () {
    if (!this.preview) tinymce.remove()
  }
}
