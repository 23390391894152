import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = [ "answer", "plus", "minus" ]

  initialize() {
    this.hide_all_answer
  }

  show_answer(event) {
    const answer = event.currentTarget.nextSibling.nextSibling;
    answer.classList.toggle("hidden")

    var index = event.currentTarget.dataset.index
    this.plusTargets[index].classList.toggle("hidden")
    this.minusTargets[index].classList.toggle("hidden")
  }

  hide_all_answer() {
    this.answerTargets.forEach(answer => {
      answer.classList.add("hidden")
    })
  }
}
