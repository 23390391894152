import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pptabs"
export default class extends Controller {
  static targets = [ "tab" ]

  initialize() {
    const hash = window.location.hash.substring(1)
    const activeTab = document.querySelector(`[data-target="${hash}"]`)
    if (hash == '' || activeTab == null) {
      this.showTab(this.tabTargets[0].id)
      const tabs = document.querySelectorAll(".tabs li")
      tabs[0].classList.add("bg-white");
    } else {
      this.hideAllTabs()
      this.setActiveTab(activeTab)
      this.showTab(hash)
    }
  }

  switchTab(event) {
    event.preventDefault()
    const targetTab = event.currentTarget.dataset.target
    this.hideAllTabs()
    this.setActiveTab(event.currentTarget)
    this.showTab(targetTab)
  }

  hideAllTabs() {
    this.tabTargets.forEach(tab => {
      tab.classList.add("hidden")
    })
  }

  setActiveTab(tab) {
    const tabs = document.querySelectorAll(".tabs li")
    tabs.forEach(t => t.classList.remove("bg-white"))
    tab.classList.add("bg-white")
  }

  showTab(id) {
    const tab = document.getElementById(id)
    if (tab != null) {
      tab.classList.remove("hidden")
    }
  }
}
