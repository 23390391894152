import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lightbox"
export default class extends Controller {
  static targets = ["modal", "background", "modalimg"];

  handleOpen(event) {
    event.preventDefault();
    var imgUrl = event.target.attributes["src"]["value"];
    this.modalimgTarget.src = imgUrl;
    this.modalTarget.classList.remove("hidden");
    this.backgroundTarget.classList.remove("opacity-0");
    this.backgroundTarget.classList.add("opacity-100");
  }

  handleClose(event) {
    event.preventDefault();
    this.modalimgTarget.src = "";
    this.modalTarget.classList.add("hidden");
    this.backgroundTarget.classList.remove("opacity-100");
    this.backgroundTarget.classList.add("opacity-0");
  }
}
